import React from "react"

import Layout2 from "../components/layout2"

import SEO from "../components/seo"

import HeroSection from "../components/home-page/heroSection"
import PricingSection from "../components/home-page/pricingSection"
import ShowcaseSection from "../components/home-page/showcaseSection"
import ContactSection from "../components/home-page/contactSection"
import ArchiveSection from "../components/home-page/archiveSection"
import Footer from "../components/footer"

import '../scss/style.scss'

import CheckoutHTML from "../components/checkoutHTML"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"


const Checkout = () => (
    <Layout2 >
        <SEO title="Checkout" />

        <Container style={{ maxWidth: "900px", fontFamily: "Roboto", height: "100%" }}>
            <Row>
                <Col >
                    <h2 style={{ marginTop: "70px" }}>Submit your order request:</h2>

                </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
                <Col>




                    <Form action="https://formspree.io/xpzypjwd" method="POST">


                        <fieldset style={{ border: "none" }}>
                            <Form.Group as={Row}>
                                <Form.Label as="legend" column sm={3}>
                                    Select
      </Form.Label>
                                <Col sm={9}>
                                    {/* <Form.Check
                                        type="radio"
                                        label="HTML Only - $75 /usd"
                                        name="Package"
                                        id="formHorizontalRadios1"
                                        checked="true"
                                        value="HTML Only"
                                    /> <span style={{ fontSize: "14px", fontWeight: "300" }}> &#40;Responsive HTML
Development , 20+ Email Client Test Report, MailChimp Template Ready, Direct Communication Access&#41; </span>
                                    <br />
                                    <br /> */}

                                    <Form.Check
                                        type="radio"
                                        label="Complete Package - $110 /usd"
                                        name="Service"
                                        id="formHorizontalRadios2"
                                        value="Complete Package - $110 /usd"

                                    />
                                    <span style={{ fontSize: "14px", fontWeight: "300" }}> &#40;Responsive Email Layout Design, Responsive HTML
                                    Development ,
{/* 20+ Email Client Test Report, MailChimp Template Ready,  */}
Direct Communication Access&#41; </span>
                                    <br />
                                    <span style={{ fontWeight: "bold" }}>Extras:</span>
                                    <Form.Check
                                        type="checkbox"
                                        label="Unlimited Template Components - $48 /usd"
                                        name="Service"
                                        id="formHorizontalRadios2"
                                        value="Unlimited Template Components - $48 /usd"

                                    />

                                    <Form.Check
                                        type="checkbox"
                                        label="Email Client Test Report - $8 /usd"
                                        name="Service"
                                        id="formHorizontalRadios2"
                                        value="Email Client Test Report - $8 /usd"

                                    />

                                    <hr style={{ marginTop: "30px" }} />

                                    <span style={{ fontWeight: "bold" }}>Other Services:</span>

                                    <Form.Check
                                        type="radio"
                                        label="Custom - Email Design Only - $75 /usd"
                                        name="Service"
                                        id="formHorizontalRadios3"
                                        value="Custom - Email Design Only - $75 /usd"


                                    />

                                    <Form.Check
                                        type="radio"
                                        label="Custom - Responsive HTML Development Only - $64 /usd"
                                        name="Service"
                                        id="formHorizontalRadios4"
                                        value="Custom - Responsive HTML Development Only - $64 /usd"
                                        style={{ paddingTop: "20px" }}

                                    />

                                    {/* <Form.Check
                                        type="radio"
                                        label="Custom - Email Client Test Report - $15 /usd"
                                        name="Service"
                                        id="formHorizontalRadios5"
                                        value="Custom - Responsive HTML Development Only"
                                        style={{ paddingTop: "20px" }}

                                    /> */}

                                    {/* <Form.Check
                                        type="radio"
                                        label="Other Service - Custom Logo Creation - $70 /usd"
                                        name="Service"
                                        id="formHorizontalRadios6"
                                        value="Other Service - Custom Logo Creation"
                                        style={{ paddingTop: "20px" }}

                                    /> */}
                                    <Form.Check
                                        type="checkbox"
                                        label="Content Writing - $0.10 per word, min 100 words - / USD"
                                        name="Service"
                                        id="formHorizontalRadios5"
                                        value="Content Writing - $0.10 per word, min 100 words - / USD"
                                        style={{ paddingTop: "20px" }}

                                    />


                                </Col>
                            </Form.Group>
                        </fieldset>
                        {/* <Form.Group as={Row} controlId="formHorizontalCheck">
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check label="Remember me" />
                            </Col>
                        </Form.Group> */}

                        {/* <Form.Group as={Row}>
                            <Form.Label as="legend" column sm={2}>
                                Price
      </Form.Label>
                            <Col sm={10}>
                            
                                <h2>$74 <span style={{ fontSize: "12px", fontWeight: "lighter" }}>/USD</span></h2>
                            </Col>
                        </Form.Group> */}


                        <Form.Group as={Row} className="mb-4" controlId="formBasicName">
                            <Form.Label as="legend" column sm={3}>
                                Full Name
      </Form.Label>
                            {/* <Col sm={{ span: 9, offset: 3 }}> */}
                            <Col sm={6}>

                                <Form.Control required={true} type="name" name="Name" placeholder="Name" />
                                <Form.Text className="text-muted">


                                </Form.Text>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} className="mb-4" controlId="formBasicEmail">
                            <Form.Label as="legend" column sm={3}>
                                Email
      </Form.Label>
                            <Col sm={6}>
                                <Form.Control required={true} type="email" name="_replyto" placeholder="Email" />
                            </Col>

                        </Form.Group>

                        <Form.Group as={Row} controlId="formBasicTextArea">
                            <Form.Label as="legend" column sm={3}>
                                Message
      </Form.Label>
                            <Col sm={9}>
                                <Form.Control name="Message" as="textarea" aria-label="With textarea" placeholder="Enter your message here" style={{ fontFamily: "Roboto", height: "180px" }} />

                            </Col>
                        </Form.Group>



                        <Form.Group as={Row}>
                            <Col sm={12}>
                                <Button type="submit">Submit Request</Button>

                            </Col>
                        </Form.Group>
                    </Form>




                    {/* <CheckoutHTML type="submit" /> */}


                </Col>
            </Row>
        </Container>









        <Footer />





    </Layout2 >
)

export default Checkout
